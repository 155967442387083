<template>
  <PageContent
    class="fiche-etablissement"
    :page-title="organisme && organisme.nom_complet"
  >
    <template #header-right>
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(currentUrl)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ContextMenu
        :bloc-actions-globales="actionsGlobales"
        @click-action="handleActions($event)"
      />
    </template>

    <template
      v-if="organisme && !isLoading"
      #content
    >
      <EstablishmentOverview
        :organisme="organisme"
        :budget="budget"
        @change-img="fetchOrganisme()"
      />
      <EstablishmentContactInfos :organisme="organisme" />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  copyToClipboard,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import EstablishmentOverview from "@/components/establishments/EstablishmentOverview.vue";
import EstablishmentContactInfos from "@/components/establishments/EstablishmentContactInfos.vue";
import ContextMenu from "@/components/ContextMenu.vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

import IconChatFileExport from "@/components/icons/IconChatFileExport.vue";
import { UilShareAlt } from "@iconscout/vue-unicons";

/**
 * Vue d'un établissement en particulier avec toutes ses informations.
 */
export default {
  name: "FicheEtablissement",
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    InfiniteLoader,
    EstablishmentOverview,
    EstablishmentContactInfos,
    ContextMenu,
    UilShareAlt,
  },
  data() {
    return {
      currentUrl: window.location.href,
      organisme: null,
      infosOrganisme: null,
      isLoading: false,
      budget: {
        type: "",
      },
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isHorsMarche", "hasPerm", "isGE", "exportingStatsMarche", "hasMail"]),
    actionsGlobales() {
      if (!this.exportingStatsMarche) {
        if (this.hasPerm("can_view_as_maitre_compta") && this.isGE && this.hasMail) {
          return [
            {
              title: this.$t("action.actions-sur-donnees-ensemble-etablissements"),
              actions: [
                {
                  slug: "ask_export_xls",
                  label: this.$t("action.demande-export-xls-stats"),
                  icon: IconChatFileExport,
                },
              ],
            },
          ];
        }
      }

      return [];
    },
  },
  beforeMount() {
    this.fetchOrganisme();
  },
  methods: {
    copyToClipboard,
    /**
     * Récupère les infos de l'établissement de la page.
     */
    fetchOrganisme() {
      Api().get(`/organisme/${this.$route.params.id || this.organismeActuel.id}/`)
        .then((res) => {
          this.organisme = res.data;

          this.fetchInfosOrganisme();

          this.$store.dispatch("fetchBudgetOrganisme", this.$route.params.id || this.organisme.id)
            .then((resBudget) => {
              this.budget = {
                ...resBudget,
                type: resBudget.type === "QUANTITE" ? "licences" : "euros",
              };
            });
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$store.commit("setErrorStatus", err.response.status);
          }
        });
    },
    /**
     * Récupère les infos de contact, des devis et des commandes de l'établissement.
     */
    fetchInfosOrganisme() {
      Api().get(`/organisme/${this.$route.params.id || this.organismeActuel.id}/infos/`)
        .then((res) => {
          this.infosOrganisme = res.data;
          this.organisme = { ...this.organisme, ...this.infosOrganisme };
        });
    },
    /**
     * Callback des actions du menu contextuel.
     * @param {Object} action Objet definissant l'action avec son slug, son label...
     */
    handleActions(action) {
      switch (action.slug) {
        case "ask_export_xls":
          this.$store.dispatch("askExportStatsMarche");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss"></style>
