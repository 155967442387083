<template>
  <section id="establishment_overview">
    <div
      v-if="isHorsMarche && organisme.infos.charge_clientele"
      id="attache_commercial"
    >
      <h2 class="s5 title-separator">
        <span class="bold">
          {{ organisme.pays_livraison.toLowerCase() === "france"
            ? $t("etablissement.conseiller-colleges-lycees")
            : $t("etablissement.responsable-de-zone") }}
        </span>
      </h2>

      <div class="grid-zone">
        <div id="block_left">
          <p class="text-regular">
            {{ $t('general.nhesitez-pas-a-me-contacter') }}
          </p>
          <div
            v-if="maitreSite && canAccessDesiderata(maitreSite)"
            id="desiderata_infos"
          >
            <p
              v-if="
                maitreSite.infos.date_expedition_prevue
                  && $moment(maitreSite.infos.date_expedition_prevue) > $moment().subtract(1, 'days')
              "
              class="text-regular"
            >
              <UilTruck class="truck" />
              {{
                $t('livraison.prochaine-expedition-est-prevue', {
                  date: $moment(maitreSite.infos.date_expedition_prevue).format($t("global.format-date")),
                })
              }}
            </p>

            <ButtonClassic
              variant="ghost"
              :label="
                maitreSite.infos.date_demande_livraison
                  ? $t('livraison.consulter-mes-dates-livraison')
                  : $t('livraison.choisir-mes-dates-livraison')
              "
              color="primary"
              icon="right"
              @click="$modal.show('modal_dates_livraison')"
            >
              <template #right-icon>
                <UilCalender />
              </template>
            </ButtonClassic>
          </div>
        </div>
        <div id="block_right">
          <div>
            <img
              v-if="organisme.infos.charge_clientele.avatar"
              class="photo"
              :src="organisme.infos.charge_clientele.avatar"
              :alt="$t('general.photo-charge-clientele')"
            />
            <img
              v-else
              :src="require('@lde/core_lde_vue/dist/assets/media/logos/logo_lde_white.svg')"
              :alt="$t('general.logo-lde')"
            />
            <span class="text-regular bold">
              {{ organisme.infos.charge_clientele.prenom }} {{ organisme.infos.charge_clientele.nom }}
            </span>
          </div>
          <address class="text-regular flex-vcenter">
            <p>
              <span v-if="isGMBH">
                LDE GmbH & Co KG<br />
                Robert-Koch-Str. 35<br />
                77694 Kehl<br />
                DEUTSCHLAND<br />
              </span>
              <span v-else>
                LDE<br />
                Artemis SARL<br />
                1, Rue de Kyoto<br />
                67150 ERSTEIN<br />
                FRANCE
              </span>
              <span>
                <UilGlobe size="16" />
                <a
                  :href="urlLde"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span class="underline">{{ urlLde }}</span>
                </a>
              </span>
            </p>
            <hr />
            <p>
              <span>
                <UilPhone size="16" />
                {{ organisme.infos.charge_clientele.telephone || "-" }}
              </span>
              <span>
                <UilEnvelopeAlt size="16" />
                <a
                  v-if="organisme.infos.charge_clientele.email"
                  :href="`mailto:${organisme.infos.charge_clientele.email}`"
                >
                  <span>{{ organisme.infos.charge_clientele.email }}</span>
                </a>
                <span v-else>{{ organisme.infos.charge_clientele.email }}</span>
              </span>
            </p>
          </address>
        </div>
      </div>
    </div>

    <div
      id="main_infos"
      class="flex-vcenter"
    >
      <figure id="img_etablissement">
        <img
          v-if="organisme.infos.image"
          :src="organisme.infos.image"
          :alt="$t('general.alt-image-etablissement')"
        />
        <img
          v-else-if="organisme.infos.image_from_library"
          :src="require(
            `@lde/core_lde_vue/dist/assets/media/etablissements/${organisme.infos.image_from_library}`
          )"
          :alt="$t('general.alt-image-etablissement')"
        />
        <img
          v-else
          :src="require(`@lde/core_lde_vue/dist/assets/media/${typeEcole}`)"
          :alt="$t('general.alt-image-etablissement')"
        />
        <ButtonClassic
          variant="special"
          :label="$t('action.changer-image')"
          color="primary"
          size="small"
          icon="left"
          @click="$modal.show('modal_change_image')"
        >
          <template #left-icon>
            <IconImage />
          </template>
        </ButtonClassic>
      </figure>
      <div id="commandes">
        <DataBloc
          v-if="hasPerm('view_commande')"
          :label="$t('etablissement.commandes')"
        >
          <template #icon>
            <IconCommande height="64" />
          </template>
          <template #number>
            {{ organisme.NB_CMD | spaceNumber }}
          </template>
        </DataBloc>
        <div>
          <DataBloc
            v-if="!isHorsMarche && hasPerm('view_devis')"
            :label="typeMarcheTraduction().etablissementDevisEnAttenteTransmissionRegion"
            variant="horizontal"
            small
          >
            <template #icon>
              <IconFileDevisCheck height="32" />
            </template>
            <template #number>
              {{ organisme.DEVIS_A_SOUMETTRE | spaceNumber }}
            </template>
          </DataBloc>
          <DataBloc
            v-if="hasPerm('view_devis')"
            :label="$t('etablissement.devis-en-attente-de-commande')"
            variant="horizontal"
            small
          >
            <template #icon>
              <IconFileDevis height="32" />
            </template>
            <template #number>
              {{ organisme.DEVIS_A_COMMANDER | spaceNumber }}
            </template>
          </DataBloc>
        </div>
      </div>
      <template v-if="budget.dotation > 0">
        <DataBloc
          v-if="budget.type === 'euros' && hasPerm('view_devis')"
          :label="$t('etablissement.restants-sur-un-budget-total-de-x', [ moneyFilter(budget.dotation), ])"
        >
          <template #icon>
            <IconBudget height="64" />
          </template>
          <template #number>
            {{ budget.soldeAVenir | moneyFilter }}{{ "\xa0" }}€
          </template>
        </DataBloc>
        <DataBloc
          v-else-if="budget.type === 'licences' && hasPerm('view_devis')"
          :label="$t('etablissement.ressources-sur-x', [ spaceNumber(budget.dotation), ])"
        >
          <template #icon>
            <IconLibrary height="64" />
          </template>
          <template #number>
            {{ budget.soldeAVenir | spaceNumber }}
          </template>
        </DataBloc>
      </template>
    </div>
    <ModalChangeImage
      variant="etablissement"
      :user="user"
      :organisme-actuel="organisme"
      :modal-show="showImageModal"
      @validate-img="changeImage($event, organisme)"
      @toggle-modal="showImageModal = $event"
    />
    <ModalDatesLivraison
      v-if="maitreSite"
      :maitre-site="maitreSite"
      @at-open="!maitreSite.infos.date_demande_livraison ? fetchMaitreSite() : null"
      @submit="fetchMaitreSite"
    />
    <!-- Les infos de la modale de livraison ne sont pas mises à jour sans refaire la requête avant ouverture -->
  </section>
</template>

<script>
import {
  ButtonClassic,
  ModalChangeImage,
  moneyFilter,
  spaceNumber,
} from "@lde/core_lde_vue";

import DataBloc from "@/components/establishments/DataBloc.vue";
import ModalDatesLivraison from "@/components/modals/ModalDatesLivraison.vue";

import IconCommande from "@/components/icons/IconCommande.vue";
import IconFileDevisCheck from "@/components/icons/IconFileDevisCheck.vue";
import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconBudget from "@/components/icons/IconBudget.vue";
import IconLibrary from "@/components/icons/IconLibrary.vue";
import IconImage from "@/components/icons/IconImage.vue";

import {
  UilCalender,
  UilGlobe,
  UilEnvelopeAlt,
  UilPhone,
  UilTruck,
} from "@iconscout/vue-unicons";

import mixinFicheEts from "@/mixins/mixinEstablishments";

import Api from "@/modules/axios";
import typeMarcheTraduction from "@/modules/trad_utils";
import { canAccessDesiderata } from "@/modules/utils";

import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Affiche les informations globales de l'établissement.
 */
export default {
  name: "EstablishmentOverview",
  components: {
    DataBloc,
    ButtonClassic,
    ModalChangeImage,
    ModalDatesLivraison,
    IconCommande,
    IconFileDevisCheck,
    IconFileDevis,
    IconBudget,
    IconLibrary,
    IconImage,
    UilCalender,
    UilGlobe,
    UilEnvelopeAlt,
    UilPhone,
    UilTruck,
  },
  mixins: [mixinFicheEts],
  props: {
    /**
     * Établissement avec ses informations.
     */
    organisme: {
      type: Object,
      required: true,
    },
    /**
     * Informations liées au budget.
     */
    budget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showImageModal: false,
      maitreSite: null,
    };
  },
  computed: {
    ...mapGetters(["user", "isHorsMarche", "hasPerm"]),
    typeEcole() {
      const primaires = ["EE", "EEA", "EM"];

      const colleges = ["CEG SPE", "CLG"];

      const lycees = [
        "MFR",
        "LEGT",
        "LG",
        "LT et LP",
        "LT",
        "EREA",
        "LP et LEGT",
        "LEG",
        "LYC",
        "LEGTPA",
        "L. AG",
        "EXP",
        "LP-LGT",
        "LEGTA",
        "LP - METIER",
        "LG",
        "LPO - METIER",
        "SEP",
        "LGT-LP",
        "EPLEFPA",
        "LP-GT",
        "ERA",
        "LP",
        "LCL",
        "CFA",
        "LPA",
        "LPO",
        "LGT",
      ];

      if (primaires.includes(this.organisme.infos.sigle)) {
        return "etablissements/primaire.svg";
      }
      if (colleges.includes(this.organisme.infos.sigle)) {
        return "etablissements/college.svg";
      }
      if (lycees.includes(this.organisme.infos.sigle)) {
        return "etablissements/lycee.svg";
      }
      return "missing_img/no_img_image.svg";
    },
    urlLde() {
      return config.url.lde;
    },
  },
  mounted() {
    this.fetchMaitreSite();
  },
  methods: {
    typeMarcheTraduction,
    moneyFilter,
    spaceNumber,
    canAccessDesiderata,
    /**
     * Récupère le maître site de l'établissement de la fiche.
     * @returns {Promise} Maître site de l'organisme lié à la commande.
     */
    fetchMaitreSite() {
      return Api().get(`/organisme/${this.organisme.infos.maitre_site || this.organisme.id}/`)
        .then(({ data }) => {
          this.maitreSite = data;
        })
        .catch((err) => {
          // Si on n'a pas accès au maître site mais qu'il existe, on ne fait rien car c'est un cas possible
          if (err.response.status !== 404) {
            this.$toast.error({ title: err.response.data });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/establishments/establishment_overview.scss";
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#establishment_overview {
  #block_left {
    display: flex;
    flex-direction: column;
  }

  #desiderata_infos {
    p.text-regular { margin: var.$space-x-tiny 0; }
    .truck { transform: scaleX(-1); }
  }
}
</style>
